/*
Server RPCs that do not need to be saved to Redux
*/
import nimbioServer from "./endpoints";
import { call } from "../utilities/connection";

export const adminGetCommunityKeyUsageHistory = async (communityID, laterDate, earlierDate, page = 0) => {
  const response = await call(
    nimbioServer.admin.getCommunityKeyUsageHistoryV3,
    [communityID, laterDate, earlierDate, page],
    "server"
  );
  if (!response.result)
    console.error(nimbioServer.admin.getCommunityKeyUsageHistoryV3 + " failed: " + JSON.stringify(response));
  return response;
};
export const communityGetComercialKeyUsageHistory = async (communityID, laterDate, earlierDate, page = 0) => {
  const response = await call(
    nimbioServer.community.manager.getCommercialKeyUsageHistoryV3,
    [communityID, laterDate, earlierDate, page],
    "server"
  );
  if (!response.result)
    console.error(
      nimbioServer.community.manager.getCommercialKeyUsageHistoryV3 + " failed: " + JSON.stringify(response)
    );
  return response;
};
export const communityGetResidentialKeyUsageHistory = async (communityID, laterDate, earlierDate, page = 0) => {
  const response = await call(
    nimbioServer.community.manager.getResidentialKeyUsageHistoryV3,
    [communityID, laterDate, earlierDate, page],
    "server"
  );
  if (!response.result)
    console.error(
      nimbioServer.community.manager.getResidentialKeyUsageHistoryV3 + " failed: " + JSON.stringify(response)
    );
  return response;
};
export const adminDeviceGetConnectionHistory = (box_id, page_number, results_per_page) => {
  return call(
    nimbioServer.admin.box.getConnectionHistory,
    [box_id, page_number, results_per_page],
    "device-status-reducer"
  );
};

/*Needed until data is added to community subscription*/
export const adminGetCommunityFromServer = async (communityID) => {
  const response = await call(nimbioServer.admin.getCommunity, [communityID], "server");
  if (!response) console.error(nimbioServer.admin.getCommunity + " failed: " + JSON.stringify(response));
  return response;
};

export const communityDirectoryGetListing = (community_uuid) => {
  return call(nimbioServer.admin.directory.getDirectory, [community_uuid], "server");
};

export const communityDirectoryStartCall = async (calling_account_id, community_id) => {
  return call(nimbioServer.admin.directory.startCall, [calling_account_id, community_id], "server");
};

export const communityDirectoryGetVideoCallRoomDetails = (call_id) => {
  return call(nimbioServer.admin.directory.getRoomDetails, [call_id], "server");
};
