import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import MUIDataTable from "mui-datatables";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { setCredentials, startAuthConnection } from "../../reducers/connection-reducer";
import { communityDirectoryGetListing, communityDirectoryStartCall } from "../../server/server";

const DirectoryPage = (props) => {
  const { community_uuid } = useParams();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [communityName, setCommunityName] = useState("");
  const [communityID, setCommunityID] = useState(-1);
  const [open, setOpen] = React.useState(false);
  const [callingAccountID, setCallingAccountID] = useState(null);
  const [callingName, setCallingName] = useState(null);

  useEffect(() => {
    console.log(
      props.realmState.connected,
      props.realmState.connecting,
      localStorage.getItem("auth_id"),
      localStorage.getItem("secret")
    );
    if (!props.realmState.connecting && !props.realmState.connected) {
      if (localStorage.getItem("auth_id") && localStorage.getItem("secret")) {
        props.dispatch(setCredentials(localStorage.getItem("auth_id"), localStorage.getItem("secret")));
      } else {
        props.dispatch(startAuthConnection());
      }
    }
  }, [props]);

  useEffect(() => {
    if (props.realmState.connected) {
      directoryData();
    }
  }, [props.realmState.connected]);

  const handleClose = () => {
    setOpen(false);
  };

  const directoryData = async () => {
    const data = await communityDirectoryGetListing(community_uuid);
    if (!data.result) {
      console.error("Error getting directory listing.", data.message);
    }
    setMembers(data.message.members);
    setCommunityName(data.message.community_name);
    setCommunityID(data.message.community_id);
  };

  const onConfirmCall = async () => {
    const callDetails = await communityDirectoryStartCall(callingAccountID, communityID);
    if (callDetails.result) {
      navigate("/directory-call/" + callDetails.message.room_sid + "/" + callDetails.message.token);
    }
  };

  const onRowClick = async (rowData) => {
    setCallingAccountID(rowData[2]);
    setCallingName(rowData[0] + " " + rowData[1]);
    setOpen(true);
  };

  const options = {
    enableNestedDataAccess: ".",
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onRowClick: onRowClick,
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    selectableRows: "none",
    rowsPerPage: 15,
  };

  const columns = [
    { label: "First Initial", name: "first_initial", options: { searchable: false } },
    { label: "Last Name", name: "last_name" },
    { label: "account_id", name: "account_id", options: { display: "excluded", searchable: false } },
  ];

  if (!props.realmState.connected) {
    return <>Connecting...</>;
  }
  return (
    <Box sx={{ margin: 1 }}>
      {communityName && <Typography variant="h6">{communityName} Directory</Typography>}
      <MUIDataTable title={"Directory"} data={members} columns={columns} options={options} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Start Video Call"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Start a video call with {callingName}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmCall} autoFocus variant="contained">
            Call
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    realmState: state.realmState,
    loginState: state.loginState,
  };
};

export default connect(mapStateToProps)(DirectoryPage);
