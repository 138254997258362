import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import CardMedia from "@mui/material/CardMedia";
import { Card, CardContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { communityDirectoryGetVideoCallRoomDetails } from "../../server/server";
import { setCredentials, startAuthConnection } from "../../reducers/connection-reducer";
import NimbioLogo100 from "../../assets/image/nimbio-logo-100x100.png";

const VideoCallReceiveLanding = (props) => {
  const { dc_uuid } = useParams();
  const navigate = useNavigate();
  const [roomSID, setRoomSID] = useState(null);
  const [roomJWT, setRoomJWT] = useState(null);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    console.log(
      props.realmState.connected,
      props.realmState.connecting,
      localStorage.getItem("auth_id"),
      localStorage.getItem("secret")
    );
    if (!props.realmState.connecting && !props.realmState.connected) {
      if (localStorage.getItem("auth_id") && localStorage.getItem("secret")) {
        props.dispatch(setCredentials(localStorage.getItem("auth_id"), localStorage.getItem("secret")));
      } else {
        props.dispatch(startAuthConnection());
      }
    }
  }, [props]);

  useEffect(() => {
    if (props.realmState.connected) {
      getRoomData();
    }
  }, [props.realmState.connected]);

  const onClickAnswer = () => {
    navigate("/directory-call/" + roomSID + "/" + roomJWT);
  };

  const getRoomData = async () => {
    const data = await communityDirectoryGetVideoCallRoomDetails(dc_uuid);
    if (!data.result) {
      console.error("Error getting directory listing.", data.message);
      setError("No room found.");
    } else {
      console.log("!!!!", data);
      setRoomSID(data.message.room_sid);
      setRoomJWT(data.message.jwt);
    }
  };

  return (
    <div style={classes(theme).outerdiv}>
      <Card style={classes(theme).card} data-testid="login-card">
        <CardMedia component="img" style={classes(theme).media} image={NimbioLogo100} title="Nimbio" />
        <CardContent>
          <Typography variant="h6" gutterBottom style={classes(theme).signinText}>
            Call From Directory
          </Typography>
          <Typography variant="subtitle1" gutterBottom style={classes(theme).signinSubtitle}>
            {error}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={onClickAnswer}
            disabled={roomSID === null || roomJWT === null}
            variant="contained"
            style={classes(theme).button}>
            Answer Call
          </Button>
        </CardActions>
        <Typography variant={"caption"}></Typography>
      </Card>
    </div>
  );
};

const classes = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  input: {
    display: "none",
  },
  outerdiv: {
    padding: "30px",
  },
  card: {
    maxWidth: "345px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    borderRadius: "10px",
  },
  media: {
    minHeight: "100px",
    objectFit: "contain",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  signinText: {
    textAlign: "center",
  },
  signinSubtitle: {
    textAlign: "center",
    paddingBottom: "22px",
  },
  onetimecodebody: {},
  phoneInput: {
    fontSize: 22,
  },
  error: {
    color: "red",
    marginTop: "16px",
  },
});

const mapStateToProps = (state) => {
  return {
    realmState: state.realmState,
    loginState: state.loginState,
  };
};

export default connect(mapStateToProps)(VideoCallReceiveLanding);
