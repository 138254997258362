import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";

const Participant = ({ participant, isLocal = false }) => {
  const theme = useTheme();
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div style={classes(theme, isLocal).videoDiv}>
      {/*<h3>{participant.identity}</h3>*/}
      <video ref={videoRef} autoPlay={true} style={classes(theme, isLocal).video} />
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
};

const classes = (theme, isLocal) => ({
  videoDiv: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  video: {
    width: isLocal ? "50%" : "100%",
    maxWidth: "600px",
    display: "block",
    marginLeft: "auto",
    marginRight: isLocal ? "0" : "auto",
    borderRadius: "6px",
  },
});

export default Participant;
