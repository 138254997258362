import Wampy from "wampy";
import { challengeResponse } from "./cryptoHelper";
import { nimbioConnection, setConnection } from "./connection";
import {
  connectionClosed,
  connectionReconnected,
  connectionReconnecting,
  loginSuccess,
  logout,
  startAuthConnection,
} from "../reducers/connection-reducer";
import store from "../store";
import nimbioServer from "../server/endpoints";

type Callback = () => void;
type Dict = { [key: string]: any };
type ChallengeCallback = (_auth_method: string, extra: Dict) => string;
type WampyOptions = {
  debug?: boolean; //default false
  logger?: object | null; //default null
  autoReconnect?: boolean; //default true
  reconnectInterval?: number; //default 2000(ms)
  maxRetries?: number; //default 25
  realm?: string | undefined | null; //default null
  helloCustomDetails?: any; //default null
  uriValidation?: "strict" | "loose"; //default "strict"
  authid?: string | null; //default null
  authmethods?: string[]; // default []
  authextra?: object; //default {}
  authPlugins?: object; //default {}
  authMode?: "manual" | "auto"; //default "manual"
  onChallenge?: ChallengeCallback | null; //default null
  onClose?: Callback | null; //default null
  onError?: (error?: { name: string; errorUri: string; details: { message: string } }) => void | null; //default null
  onReconnect?: Callback | null; //default null
  onReconnectSuccess?: Callback | null; //default null
  ws?: any; //default null
  wsRequestOptions?: any; //default null
  additionalHeaders?: any; //default null
  serializer?: any; //default JsonSerializer
  payloadSerializers?: any; //default { json: jsonSerializer }
};

let currentRealm: boolean | null = null;

export async function wampCreateConnection(auth_id: string, secret: string, url: string, isRealm1: boolean = true) {
  if (nimbioConnection.getSessionId() && currentRealm != null && isRealm1 === currentRealm) {
    return;
  }
  currentRealm = isRealm1;

  let realmOptions: WampyOptions = {
    debug: false,
    autoReconnect: true,
    maxRetries: 1000,
    reconnectInterval: 500,
    ws: WebSocket,
    uriValidation: "loose",
    wsRequestOptions: {
      rejectUnauthorized: false,
    },
    onError: (error) => {
      console.log("<B> Some error on server", error?.errorUri, error?.details);
      if (error?.errorUri === nimbioServer.noSuchUser) {
        // TODO(rick) clean this up
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        clearTimeout(nimbioConnection._cache.timer);
        // TODO(rick) clean this up
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        nimbioConnection._options.autoReconnect = false;
        store.dispatch(logout());
        store.dispatch(startAuthConnection());
      }
    },
    onClose: () => {
      console.log(nimbioConnection);
      store.dispatch(connectionClosed());
    },
    onReconnect: () => {
      store.dispatch(connectionReconnecting());
    },
    onReconnectSuccess: () => {
      store.dispatch(connectionReconnected());
    },
  };

  if (isRealm1) {
    realmOptions = {
      ...realmOptions,
      realm: "realm1",
      authid: auth_id,
      authmethods: ["wampcra"],
      onChallenge: (method, info) => {
        return challengeResponse(secret, info.challenge);
      },
    };
  } else {
    realmOptions = {
      ...realmOptions,
      realm: "auth-realm",
    };
  }

  // Initialize the connection.
  try {
    setConnection(new Wampy(url, realmOptions as wampy.WampyOptions));
    await nimbioConnection.connect();
    store.dispatch(loginSuccess());
  } catch (e) {
    console.log("connection failed", e);
  }
}
