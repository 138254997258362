import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NoAccountDialog from "./Components/NoAccountDialog";
import { getLoginDetails } from "../../reducers/profile-reducer";
import endpoints from "../../routes/endpoints";

const basePageRedirect = (profile, navigate, pathname) => {
  /*
  Allow URI if contains cm
  */
  if (pathname.includes("community-manager")) {
    navigate(pathname);
  }

  /*
  Prioritize the page redirect to match the specificity of the account
  (admin > distributor > installer > community manager)
  */
  if (pathname === endpoints.ROOT) {
    // Ensure username is set
    if (profile.loaded && !profile.loading && !profile.error) {
      if (
        profile.details.first_name === "" ||
        profile.details.last_name === "" ||
        profile.details.first_name === null ||
        profile.details.last_name === null
      ) {
        return navigate(endpoints.NAME);
      }
    }

    // Admin
    if (profile.details.is_admin) {
      return navigate(endpoints.ADMIN_DEVICE_MONITOR);
    }

    // Community Manager
    if (profile.details.is_community_manager) {
      return navigate(endpoints.PICK_COMMUNITY);
    }

    // Distributor
    if (profile.details.is_distributor) {
      return navigate(endpoints.DISTRIBUTOR);
    }

    // Installer
    if (profile.details.is_installer) {
      return navigate(endpoints.INSTALLER);
    }
    // Todo: the default should be some kind of error message if user does not meet credentials
    // Default
    return navigate(endpoints.PICK_COMMUNITY);
  }
};

const BasePage = () => {
  const realm = useSelector((state) => state.realmState);
  const profile = useSelector((state) => state.profileState);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Direct to Login if auth isn't in local storage
    if (localStorage.getItem("auth_id") !== null && localStorage.getItem("secret") !== null) {
      // Only dispatch getLoginDetails if profile never loads and realm is connected
      if (!profile.loading && !profile.loaded && realm.connected) {
        // Load the profile
        dispatch(getLoginDetails());
      }
    } else {
      navigate(endpoints.LOGIN);
      return;
    }

    if (profile.loading || !realm.connected) {
      if (localStorage.getItem("auth_id") === null || localStorage.getItem("secret") === null) {
        navigate(endpoints.LOGIN);
        return;
      }
    }

    if (realm.connected && !profile.loading && profile.loaded) {
      basePageRedirect(profile, navigate, location.pathname);
    }
  }, [location.pathname, realm, profile, dispatch, navigate]);

  if (!profile.loaded && !profile.loading && realm.connected) {
    return <NoAccountDialog open={true} />;
  }

  if (!realm.connected || !profile.loaded) {
    return <>Loading Profile...</>;
  }

  if (!realm.connected) {
    return <>Loading...</>;
  }

  return <Outlet />;
};

export default BasePage;
