import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import * as Video from "twilio-video";

import { Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Participant from "./Participant";

const VideoCall = () => {
  const { room_name, token } = useParams();
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
    };

    console.log("Connecting to room: ", room_name, " with token: ", token);

    Video.connect(token, {
      name: room_name,
    }).then((room) => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [room_name, token]);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ));

  return (
    <div style={classes(theme).outerdiv}>
      <Card style={classes(theme).card} data-testid="login-card">
        {/*<CardMedia component="img" style={classes(theme).media} image={NimbioLogo100} title="Nimbio" />*/}
        <CardContent>
          <Typography variant="h6" gutterBottom style={classes(theme).signinText}>
            Nimbio Video Call
          </Typography>
          <div className="remote-participants">{remoteParticipants}</div>
          <div className="local-participant">
            {room ? (
              <Participant key={room.localParticipant.sid} participant={room.localParticipant} isLocal={true} />
            ) : (
              ""
            )}
          </div>
        </CardContent>
        {/*<CardActions>*/}
        {/*  <Button*/}
        {/*    onClick={onClickAnswer}*/}
        {/*    disabled={roomSID === null || roomJWT === null}*/}
        {/*    variant="contained"*/}
        {/*    style={classes(theme).button}>*/}
        {/*    Answer Call*/}
        {/*  </Button>*/}
        {/*</CardActions>*/}
        <Typography variant={"caption"}></Typography>
      </Card>
    </div>
  );
};

const classes = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  input: {
    display: "none",
  },
  outerdiv: {
    padding: "30px",
  },
  card: {
    maxWidth: "100vw",
    marginLeft: "auto",
    marginRight: "auto",
    // padding: "10px",
    borderRadius: "10px",
  },
  media: {
    minHeight: "100px",
    objectFit: "contain",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  signinText: {
    textAlign: "center",
  },
  signinSubtitle: {
    textAlign: "center",
    paddingBottom: "22px",
  },
  onetimecodebody: {},
  phoneInput: {
    fontSize: 22,
  },
  error: {
    color: "red",
    marginTop: "16px",
  },
});

const mapStateToProps = (state) => {
  return {
    realmState: state.realmState,
    loginState: state.loginState,
  };
};

export default connect(mapStateToProps)(VideoCall);
